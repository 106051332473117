body {
  font: 14px monospace;
}

.container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.board {
  display: grid;
  padding: 20px;
  border: 1px solid #000;
  width: 532px;
  height: 532px;
  justify-content: center;
  align-content: center;
}

.cell {
  font-size: 20px;
  margin-top: -1px;
  margin-right: -1px;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border: 1px solid #000;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.leaderboard {
  min-width: 500px;
  margin: 0 auto 20px auto;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard-table td,
.leaderboard-table th {
  border: 1px solid black;
  padding: 5px 10px;
}

.leaderboard-table th {
  border: 1px solid white;
  color: white;
  background: black;
}

.leaderboard-game-row {
  background: #c5c5c5;
}

.align-left {
  float: left;
}
.align-right {
  float: right;
}

.leaderboard-nav {
  margin-top: 5px;
}

.leaderboard-nav button {
  background: white;
  border: 1px solid black;
  padding: 0 5px;
  cursor: pointer;
}

.leaderboard-nav button:hover {
  background: #e8e8e8;
  transform: scale(1.15);
}

.leaderboard-nav button:active {
  background: #c5c5c5;
  transform: scale(1);
}
.cell:hover {
  background: #e8e8e8;
}

.cell-selected {
  background: #ff4e50;
}

.cell-selected:hover {
  background: #cf272a;
}

.word {
  height: 50px;
  font-size: 50px;
}

.level {
  margin-top: 10px;
  height: 20px;
  font-size: 20px;
}

.game-over {
  font-size: 36px;
}

.selected {
  color: #cf272a;
}

.timer {
  font-size: 36px;
  color: #cf272a;
}

.name-input {
  background: #fff;
  border: 1px solid black;
  font-size: 24px;
  padding: 10px;
  margin-right: 5px;
}

.play-button {
  background: #ffd7d7;
  border: 1px solid black;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}

.play-button:hover {
  background: #ffa8a8;
  transform: scale(1.05);
}

.play-button:active {
  background: #ff9a9a;
  transform: scale(1);
}
